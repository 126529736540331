import React from 'react'
import { Link } from 'gatsby'
import { graphql } from "gatsby"

import Layout from '../components/layout'

const IndexPage = ({
  data: { allMarkdownRemark: { group } },
}) => {
  return (
    <Layout>
      <div>
        <h2>Help Topics</h2>
        { group.map(renderGroup) }
      </div>
    </Layout>
  )
}

const renderGroup = ({ fieldValue, edges }) => {
  return (
    <div key={ fieldValue }>
      <h3>{ fieldValue }</h3>
      <ul>
        { edges.map(renderEdge) }
      </ul>
    </div>
  )
}

const renderEdge = ({ node: { fields: { slug }, frontmatter: { title }, excerpt } }) => {
  return (
    <li key={ slug }>
      <Link to={ slug }>
        { title }
      </Link>
    </li>
  )
}

export const query = graphql`
  query {
    allMarkdownRemark(sort: { fields: [frontmatter___title], order: ASC }) {
      group(field: frontmatter___categories) {
        fieldValue
        totalCount
        edges {
          node {
            id
            frontmatter {
              title
              categories
            }
            excerpt
            fields {
              slug
              categorySlugs
            }
          }
        }
      }
    }
  }
`

export default IndexPage
