import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import logo from '../graphics/logo-remente.svg'

import Header from './header.js'
import './layout.css'

const Layout = ({ children, data }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>

        <Helmet
          title={data.site.siteMetadata.title}
          meta={[
            { name: 'description', content: 'Find answers to your questions about Remente.' },
            { name: 'keywords', content: 'Remente, Help, Support, FAQ, Remente App, Personal Development' },
          ]}
        />

        <div className="layout-wrap">
          <Header siteTitle={ data.site.siteMetadata.title } />

          <div className="layout-wrap">
            <div className="main">
              {children}
            </div>
          </div>
        </div>

        <div className="footer">
          <div className="footer-content">
            <a href="https://remente.com"><img src={ logo } alt="" /></a>
            <small>Copyright © { new Date().getFullYear() } Remente AB. All rights reserved.</small>
          </div>
        </div>

      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
