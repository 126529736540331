import React from 'react'
import { Link, StaticQuery, graphql } from 'gatsby'
import createSlug from 'slug'
import theme from '../theme.js'
import './header.css'

createSlug.defaults.mode = 'rfc3986'

const Render = ({ data, siteTitle }) => (
  <>
    <div className="header">
      <div
        style={{
          margin: '0 auto',
          padding: '1.45rem 2%',
          paddingBottom: 0,
        }}
      >

        <h1 style={{ margin: 0, fontSize: '12px', fontWeight: 600, border: 'none' }}>
          <Link
            to="/"
            style={{
              color: theme.bodyFg,
              textDecoration: 'none',
            }}
          >
            {siteTitle}
          </Link>
        </h1>

        <div className="nav-buttons">

          <nav className="topics">
            <ul>
              <li className="nav-header">Help topics</li>
              { data.allMarkdownRemark.group.map(renderNavLink) }
            </ul>
          </nav>

          <Link to="/contact" className="contact-us">Contact Us</Link>

        </div>

      </div>
    </div>
  </>
)

function renderNavLink({ fieldValue }) {
  const slug = createSlug(fieldValue)
  return (
    <li key={ slug }><Link to={ '/' + slug }>{ fieldValue }</Link></li>
  )
}

const Header = ({ siteTitle }) => (
  <StaticQuery
    query={graphql`
      query CategoryQuery {
        allMarkdownRemark(sort: { fields: [frontmatter___title], order: ASC }) {
          group(field: frontmatter___categories) {
            fieldValue
            totalCount
          }
        }
      }
    `}
    render={ data => Render({ data, siteTitle }) }
  />
)

export default Header
